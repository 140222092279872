import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Link,
  extendTheme,
  HStack,
} from "@chakra-ui/react";
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';


const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const customTheme = extendTheme({ config });

export const App = () => {

  return (
      <ChakraProvider theme={customTheme}>
        <Box display={"flex"} flex={1}  width={"100vw"} height={"100vh"} justifyContent={"center"} alignItems={"center"}>
          <VStack>
            <Box display={"flex"}>
              <HStack>
                <Text fontSize={{ base: "45px", md: "100px" }} textColor={"#2e5496"}>AVRON</Text>
                <Text fontSize={{ base: "45px", md: "100px" }}>|</Text>
                <Text fontSize={{ base: "45px", md: "100px" }}>Conseil</Text>
              </HStack>
            </Box>
            <Box display={"flex"}>
              <Text fontSize={{ base: "15px", md: "30px" }}>Multicloud Consulting and Services</Text>
            </Box>
            <Box display={"flex"}>
              <HStack spacing={5}>
                <Link href="https://www.linkedin.com/company/avronconseil/" isExternal _hover={{ color: "blue.500" }}>
                  <FaLinkedin size="24px" />
                </Link>
                <Link href="mailto:contact@avron-conseil.com" _hover={{ color: "green.500" }}>
                  <FaEnvelope size="24px" />
                </Link>
              </HStack>
            </Box>
          </VStack>
        </Box>
      </ChakraProvider>
  )
};
